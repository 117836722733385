import Link from "next/link";
import { AiOutlineArrowLeft } from "react-icons/ai";

export default function Custom404ErrorComponent() {
  return (
    <>
      <main className="text-center d-flex">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ margin: "auto" }}
        >
          <div>
            <h1 className="mb-0 fw-bold txt-404">404</h1>
            <h2 style={{ fontWeight: 400 }}>Page introuvable</h2>

            <Link href="/">
              <button className="mt-4 btn btn-primary btn-lg">
                <div className="d-flex align-items-center justify-content-center">
                  <span>
                    <AiOutlineArrowLeft />
                  </span>
                  <span className="pl-2">Retourner aux panneaux</span>
                </div>
              </button>
            </Link>
          </div>
        </div>
      </main>
      <style jsx>{`
        .txt-404 {
          font-size: 13.3rem;
        }
        @media screen and (max-width: 450px) {
          .txt-404 {
            font-size: 8.3rem;
          }
        }
      `}</style>
    </>
  );
}
