import FrontOfficeLayout from "../components/layouts/FrontOfficeLayout";
import Custom404ErrorComponent from "../components/404";
import Navbar from "../components/navbar/Navbar";

export default function Custom404ErrorPage() {
  return (
    <FrontOfficeLayout pageTitle="Erreur 404 - Page introuvable">
      <div className="mb-2">
        <Navbar />
      </div>
      <Custom404ErrorComponent />
    </FrontOfficeLayout>
  );
}
